import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css'
import logo from './logo.svg';
import './App.css';
import FixedMenuLayout from './components/FixedMenuLayout';

class App extends Component {

  componentDidMount() {
    const title = 'TWD Research - Ovarian Cx Awareness'
    document.title = title;
    window.history.replaceState({}, title, '/projects/ovarian-cancer-awareness');
    //.replace('/projects/ovarian-cancer-awareness');
  }

  render() {
    return (
      <div className="App">
      
        <FixedMenuLayout />
      </div>
    );
  }
}

export default App;
