import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
} from 'semantic-ui-react'

export interface SectionProps {
  outerDivProps?: React.HTMLAttributes<HTMLDivElement>;
  data: {
    title: string;
    list: Array<JSX.Element | string>;
    type: 'bullets' | 'numbers';
  } | {
    title: string;
    list: string[];
    postItem?: JSX.Element;
    type: 'images';
  } | {
    title: string;
    list: string[];
    type: 'iframe';
  }
}

const Section: React.SFC<SectionProps> = (props: SectionProps) => (
  <div {...((props.outerDivProps) ? props.outerDivProps : {})}>
    <Header as='h3' inverted attached='top' style={{padding: '.2rem 1rem', borderRadius: 0}}>
      {props.data.title}
    </Header>
    <Segment attached style={{textAlign: 'left'}}>
      {(props.data.type === 'iframe')
      ? props.data.list.map((item, idx) => <iframe key={idx} src={item} style={{width: '100%', minHeight: 1200}} frameBorder="0" marginHeight={0} marginWidth={0}/>)
      : (props.data.type === 'images')
      ? [...props.data.list.map((item, idx) => <Image key={idx} src={item} />), props.data.postItem].filter(x=>x).map((x, key) =>  React.cloneElement(x!, {key}))
      : <List bulleted={props.data.type === 'bullets'} ordered={props.data.type === 'numbers'}>
        {props.data.list.map((item, idx) => <List.Item key={idx}>{item}</List.Item>)}
      </List>}
    </Segment>
</div>
)

export default Section