import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Icon,
} from 'semantic-ui-react'

import TWD_Logo from './../../TheoWyesDavid_Logo_thick.png';
import UH_Logo from './../../UHLogo.png';
import CWRUMed_Logo from './../../CWRU_School-of-Medicine_Logo.png';
import BreastVsOvarianCancer from './../../BreastVsOvarianCancer.png';
import TrendInOvarianCx from './../../TrendInOvarianCx.png';
import OvarianCancerStyled from './../../OvarianCancerStyled.png';

import Section, {SectionProps} from '../Section';
import HSChart from '../HSChart';
import { StandardLonghandProperties } from 'csstype';


function Objectkeys<T extends {}>(o: T) {
  return Object.keys(o) as Array<keyof T>;
}
function Objectentries<T extends {}>(o: T) {
  return Objectkeys(o).map(key => o[key]);
}

const legendItem = (words: string, color: StandardLonghandProperties<React.Key>['color']) =>
<div>
  <span style={{fontWeight: 'bold'}}><span style={{fontSize: '2rem', color}}>■</span> {words}</span>
</div>

const linker = (href: string, text: string | JSX.Element) => <a
  href={href}
  target="_blank"
  rel="noopener noreferrer"
>{text} <Icon name="external" size="small" style={{margin: 0}}/></a>

const posterData: { [sectionTitle: string]: SectionProps['data'] } = {
  "Introduction" : {
      title: "Introduction",
      type: 'bullets',
      list: [
          "Google answers billions of search queries every day with a USA market share of almost 80%.",
          "Tracking changes in search queries may demonstrate fluctuating public interest in a subject over time.",
          "Individuals are incentivized to ask Google exactly what they are interested in, as clearly as possible, avoiding some of the bias of regulated surveys.",
          "We analyzed changing trends in public search queries involving terms related to ovarian cancer and ovarian cancer awareness month and compared these to breast cancer."
      ]
  },
  "Objectives" : {
      title: "Objectives",
      type: 'bullets',
      list: [
          "Quantify change in public response to ovarian cancer awareness month using Google query volume."
      ]
  },
  "Methods" : {
      title: "Methods",
      type: 'bullets',
      list: [
          "Lists of search terms were obtained in eight categories (e.g. cancer type, surgery, genetics) related to ovarian and breast cancer.",
          "Lists of search terms were obtained that related specifically to ovarian cancer awareness month.",
          "The Google Health API was used to return a scaled proportion indicating popularity of each category of search terms.",
          "Sampling of the entire USA was performed weekly with 30 replicates.",
          "The most frequent searches in each category and those terms related to ovarian cancer awareness month were then compared."
      ]
  },
  "Figures" : {
      title: "Figures",
      type: 'images',
      list: [
        TrendInOvarianCx,
        BreastVsOvarianCancer
      ],
      postItem: <div style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{margin: '0 auto', border: '1px solid #d4d4d5', padding: '0 5px'}}>
          {legendItem('September (Ovarian Cancer Awareness Month)', '#7dffe75e')}
          {legendItem('October (Breast Cancer Awareness Month)', '#ff89c575')}
        </div>
      </div>
  },
  "Results" : {
      title: "Results",
      type: 'bullets',
      list: [
          "Searches related to ovarian cancer symptoms, prevention, diagnosis, surgery, genetic testing and concern for the disease did not increase in frequency over 5 years.",
          "A spike in searches for the terms “ovarian cancer” and “ovarian cancer awareness” did correlate with ovarian awareness month when compared to the preceding month (12-73 & 0-7.8 fold increase respectively). However, for both terms, peak interest was in 2012 and has progressively declined since then. From 2014-2016 peak interest was reached in the week before the start of awareness month.",
          "While searches for the terms “breast cancer” and “breast cancer awareness” also reached peak interest in 2012, breast cancer-related searches represented a much greater proportion of overall Google searches during their respective cancer awareness month."
      ]
  },
  "Conclusion" : {
      title: "Conclusion",
      type: 'bullets',
      list: [
          "Ovarian cancer awareness month has been progressively less successful in generating search interest. It was completely ineffective in most categories studied.",
          "A similar drop off in breast cancer suggests this effect may not be limited to ovarian cancer.",
          "Messaging around ovarian cancer awareness needs to change to reach a broader audience."
      ],
  },
  "References" : {
      title: "References",
      type: 'numbers',
      list: [
          <span>Carneiro HA, Mylonakis E. Google Trends: A Web-Based Tool for Real-Time Surveillance of Disease Outbreaks. <i>Clinical Infectious Diseases</i>, {linker("https://scholar.google.com/scholar?cluster=16627001758624769011", "2009;49(10):1557-1564")}.</span>,
          <span>NetMarketShare. <i>Search Engine Market Share</i>, 2018. {linker("https://netmarketshare.com/search-engine-market-share.aspx", "(https://netmarketshare.com/search-engine-market-share.aspx)")}.</span>,
          <span>Nuti SV, Wayda B, Ranasinghe I, Wang S, Dreyer RP, Chen SI, <i>et al.</i> The Use of Google Trends in Health Care Research: A Systematic Review. <i>PLoS ONE</i>, {linker("https://scholar.google.com/scholar?cluster=1949525158292551927", "2014;9(10):e109583")}.</span>
      ]
  },
  "Survey" : {
    title: 'Survey',
    type: 'iframe',
    list: [
      "https://docs.google.com/forms/d/e/1FAIpQLSfYasQRUTH4PUm5QV-jXiC8PJBIN0KEUY9R4IOtj6aPvRSCuA/viewform?embedded=true"
    ]
  }
};

const FixedMenuLayout: React.SFC<{}> = () => (
  <div>
    <Menu fixed='top' inverted style={{height: 25, paddingLeft: 0}}>
      <Container>
        <Menu.Item as='a' header style={{}}>
          <Image size='mini' src={TWD_Logo} style={{ marginRight: '1.5em' }} />
          TWD Research
        </Menu.Item>
        {undefined && <Menu.Item as='a'>Home</Menu.Item>}

        <Dropdown item simple text='Projects'>
          <Dropdown.Menu>
            <Dropdown.Header>Health</Dropdown.Header>
            <Dropdown.Item>Ovarian Cx Awareness</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {undefined && <Menu.Item>
          <span>
            Ovarian Cx Awareness
          </span>
        </Menu.Item>}
      </Container>
    </Menu>

    <div style={{ padding: '50px 0px 10px', color: 'white', backgroundColor: '#434343ff', borderBottom: '8px solid #ef3a42ff', marginBottom: 20 }}>
      <Container style={{display:'flex', flexWrap: 'wrap', marginBottom: 10}}>
        <div style={{ margin: '5px auto' }} ><Image size='small' src={UH_Logo}/></div>
        <div style={{ margin: '5px auto' }} ><Image size='small' src={CWRUMed_Logo}/></div>
      </Container>
      <div style={{ padding: '0px 15px' }}>
        <Header as='h1' style={{ color: 'white' }}>
          The Ovarian Cancer Message Isn’t Getting Out: Using Google Search Data To Gauge Ovarian Cancer Awareness
        </Header>
        <p>
          Sayuli Bhide<sup>1</sup>, Adriana Collada<sup>1</sup>, Allison B. Reid<sup>1</sup>, Carlin D. T. Jackson<sup>2</sup>, Elizabeth Hopp<sup>3</sup>, and John Nakayama<sup>3</sup>
          <br />
          <sup>1</sup>Case Western Reserve University School of Medicine, <sup>2</sup>Theo. Wyes David, Ltd., <sup>3</sup>University Hospitals Cleveland Medical Center
        </p>
      </div>
    </div>

    <Container>
      {Objectentries(posterData).map((item, idx) => <Section key={idx} data={item} outerDivProps={{style: {marginBottom: '15px'}}} />)}
    </Container>

    <Segment inverted vertical style={{ margin: '3em 0em 0em', padding: '3em 0em', borderTop: '8px solid #ef3a42ff' }}>
      <Container textAlign='center'>
        {undefined && <Grid divided inverted stackable>
          <Grid.Column width={3}>
            <Header inverted as='h4' content='Group 1' />
            <List link inverted>
              <List.Item as='a'>Link One</List.Item>
              <List.Item as='a'>Link Two</List.Item>
              <List.Item as='a'>Link Three</List.Item>
              <List.Item as='a'>Link Four</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header inverted as='h4' content='Group 2' />
            <List link inverted>
              <List.Item as='a'>Link One</List.Item>
              <List.Item as='a'>Link Two</List.Item>
              <List.Item as='a'>Link Three</List.Item>
              <List.Item as='a'>Link Four</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header inverted as='h4' content='Group 3' />
            <List link inverted>
              <List.Item as='a'>Link One</List.Item>
              <List.Item as='a'>Link Two</List.Item>
              <List.Item as='a'>Link Three</List.Item>
              <List.Item as='a'>Link Four</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={7}>
            <Header inverted as='h4' content='Footer Header' />
            <p>
              Extra space for a call to action inside the footer that could help re-engage users.
            </p>
          </Grid.Column>
        </Grid>}

        {undefined && <Divider inverted section />}
        <Image centered size='mini' src={TWD_Logo} />
        <br />
        <div>
          <span style={{display: 'inline-block'}}>All Rights Reserved.</span> <span style={{display: 'inline-block'}}>Copyright ©<span>{(new Date()).getFullYear().toString() + ' '}</span>
                        <a href="https://theowyesdavid.com" target="_blank" >Theo. Wyes David, Ltd.</a></span>
        </div>
        {undefined && <List horizontal inverted divided link size='small'>
          <List.Item as='a' href='#'>
            Site Map
          </List.Item>
          <List.Item as='a' href='#'>
            Contact Us
          </List.Item>
          <List.Item as='a' href='#'>
            Terms and Conditions
          </List.Item>
          <List.Item as='a' href='#'>
            Privacy Policy
          </List.Item>
        </List>}
      </Container>
    </Segment>
  </div>
)

export default FixedMenuLayout